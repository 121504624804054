import { BrowserRouter, Route, Routes } from "react-router-dom"
import { Contact } from "./pages/Contact"
import { Gallery } from "./pages/Gallery"
import { Home } from "./pages/Home"
import "./styles.css"

function App() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path={`/`} element={<Home />} />
        <Route path={`/gallery/`} element={<Gallery />} />
        <Route path={`/contact/`} element={<Contact />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
