import { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { animateScroll } from "react-scroll"
import "./header.css"
import logo from "./resources/logo.jpg"
export const Header = (props: { current: string }) => {
  const { pathname } = useLocation()
  const [checkboxDOM, setCheckboxDOM] = useState<HTMLInputElement | undefined>()

  useEffect(() => {
    animateScroll.scrollToTop({ duration: 1000 })
    setCheckboxDOM(document.getElementById("menu-check") as HTMLInputElement)
  }, [pathname])

  const closeMenu = () => {
    if (checkboxDOM) {
      checkboxDOM.checked = false
      animateScroll.scrollToTop({ duration: 1000 })
    }
  }

  return (
    <header>
      <div className='menu'>
        <input type='checkbox' id='menu-check' />
        <label htmlFor='menu-check' className='menu-btn'>
          <span></span>
        </label>
        <nav>
          <ul>
            <li className={props.current === "Home" ? "current" : ""}>
              <Link to='/' onClick={closeMenu}>
                HOME
              </Link>
            </li>
            <li className={props.current === "Gallery" ? "current" : ""}>
              <Link to='/gallery' onClick={closeMenu}>
                GALLERY
              </Link>
            </li>
            <li className={props.current === "Contact" ? "current" : ""}>
              <Link to='/contact' onClick={closeMenu}>
                CONTACT
              </Link>
            </li>
            <li>
              <a href='https://www.instagram.com/mm.chiyomi/'>INSTAGRAM</a>
            </li>
          </ul>
        </nav>
      </div>

      <h1>
        <Link to='/'>
          <img
            width={450}
            height={127}
            className='logo'
            alt='m.chiyomi'
            src={logo}
          />
        </Link>
      </h1>
    </header>
  )
}
