import { domAnimation, LazyMotion, m as motion } from "framer-motion"
import { Footer } from "../../components/Footer"
import { Header } from "../../components/Header"

import "./home.css"

import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { setWindowTitle } from "../../modules/setWindowTitle"
import cover_jpeg from "./resources/top_cover.jpg"

export const Home = () => {
  setWindowTitle()
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    const img = new Image()
    img.src = cover_jpeg
    img.style.animation = "imgZoomout 3s"
    img.onload = () => {
      setVisible(true)
    }
  })

  return (
    <LazyMotion features={domAnimation}>
      <Header current='Home' />
      {visible ? (
        <>
          <motion.div
            animate={{
              opacity: 1,
            }}
            initial={{
              opacity: 0,
            }}
            exit={{
              opacity: 0,
            }}
            transition={{
              duration: 1,
            }}
          >
            <main>
              <div className='spacer'></div>
              <div className='zoom-img'>
                <picture>
                  <img
                    width={1024}
                    height={912}
                    alt='バッグと使用しているフェルト'
                    src={cover_jpeg}
                    className='cover-img'
                  />
                </picture>
              </div>
              <div className='spacer'></div>
              <section className='paragraph consept'>
                <h2>Concept</h2>
                <p>
                  M.chiyomi のバッグは
                  <span className='no-wrap'>「小さな森の庭」</span>
                  から<span className='no-wrap'>生まれます。</span>
                  <br />
                  季節ごとに咲く花や木の実を写して、 愛らしく
                  <span className='no-wrap'>美しい</span>世界にたった一つの
                  <span className='no-wrap'>バッグ。</span>
                  <br />
                  花束のように、宝石のように。 女性を美しく
                  <span className='no-wrap'>見せる</span>
                  <span className='no-wrap'>アクセサリー</span>のような
                  <span className='no-wrap'>バッグ。</span>
                  <br />
                  羊毛から手作業で時間をかけ、 丁寧に製作しています。
                  <br />
                  フェルトは見た目の軽やかさとは違って、
                  何代にも渡って使い続けられる
                  <span className='no-wrap'>丈夫な素材です。</span>
                </p>
              </section>
              <section className='paragraph about-bag'>
                <h2>バッグについて</h2>
                <p>
                  バッグに付けられた花の部分は台布にまとめて綴じつけてあるため、取外しができます。
                  <br />
                  (台布ではなくバッグ本体に縫い付けてある物もあります)
                  <br />
                  取外した花は洋服のウエストや肩などに、
                  <span className='no-wrap'>コサージュ</span>
                  として使用しても素敵です。
                  <br />
                  また、羊毛という
                  <span className='no-wrap'>「持続可能性」</span>
                  のある素材の為、<span className='no-wrap'>リメイク</span>
                  が可能です。
                  <br />
                  フェルトという革よりも強い素材のバッグは、
                  <span className='no-wrap'>何代にも渡り</span>
                  大切に使用していただけます。
                  <br />
                  クリーニング、リメイクのご相談もお受けいたします。
                </p>
              </section>

              <div className='spacer'></div>

              <section style={{ textAlign: "center" }}>
                <Link to='/gallery' className='main-link'>
                  &gt; GALLERY
                </Link>
              </section>

              <div className='spacer'></div>
              <div className='spacer'></div>
            </main>
          </motion.div>
          <Footer />
        </>
      ) : (
        <div></div>
      )}
    </LazyMotion>
  )
}
