import { domAnimation, LazyMotion, m as motion } from "framer-motion"
import { useEffect, useState } from "react"
import { Description } from "../../components/Description"
import { Footer } from "../../components/Footer"
import { Header } from "../../components/Header"
import { setWindowTitle } from "../../modules/setWindowTitle"
import "./gallery.css"

export type GalleryItemType = {
  name: string
  image: string
  price?: number
  stock: number
  size?: string /*{ x: number; y: number }*/
  description: string
}

type ApiStatusType = "Loading" | "Error"

export const Gallery = () => {
  setWindowTitle("Gallery")
  const [showing, setShowing] = useState<GalleryItemType | undefined>(undefined)
  const [item, setItem] = useState<GalleryItemType[] | ApiStatusType>("Loading")
  useEffect(() => {
    //https://hirabayashi-sys.com/beta/mchiyomi_apis/getGallery.php
    fetch("https://m-chiyomi.com/apis/getGallery.php", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        const res = data
        if (res.status !== "OK") {
          setItem("Error")
        } else {
          setItem(res.response.items)
        }
      })
      .catch(() => {
        setItem("Error")
      })
  }, [])

  return (
    <LazyMotion features={domAnimation}>
      <Description data={showing} hook={setShowing} />
      <Header current='Gallery' />
      <motion.div
        animate={{
          opacity: 1,
        }}
        initial={{
          opacity: 0,
        }}
        exit={{
          opacity: 0,
        }}
        transition={{
          duration: 1,
        }}
      >
        <main>
          <div className='spacer'></div>
          <section>
            <GalleryComponent hook={setShowing} items={item} />
          </section>
        </main>
      </motion.div>
      <Footer />
    </LazyMotion>
  )
}

const GalleryComponent = (props: {
  hook: React.Dispatch<React.SetStateAction<GalleryItemType | undefined>>
  items: GalleryItemType[] | ApiStatusType
}) => {
  const fallback =
    props.items === "Loading" ? (
      <div className='fallback'>Loading</div>
    ) : (
      <div className='fallback'>
        エラーが発生しました。
        <br />
        しばらく時間をおいて再度お試しください。
      </div>
    )
  return (
    <div className='gallery-component'>
      <div className='lists'>
        {typeof props.items === "object"
          ? props.items.map((item, index) => (
              <GalleryItem key={index} data={item} hook={props.hook} />
            ))
          : fallback}
      </div>
    </div>
  )
}

const GalleryItem = (props: {
  data: GalleryItemType
  hook: React.Dispatch<React.SetStateAction<GalleryItemType | undefined>>
}) => {
  return (
    <div className='gallery-item zoom-img'>
      <img
        alt={props.data.name}
        src={
          "https://m-chiyomi.com/apis/images/" + JSON.parse(props.data.image)[0]
        }
        onClick={() => props.hook(props.data)}
      />
    </div>
  )
}
