import { domAnimation, LazyMotion, m as motion } from "framer-motion"
import { Footer } from "../../components/Footer"
import { Header } from "../../components/Header"
import { setWindowTitle } from "../../modules/setWindowTitle"
import "./contact.css"
export const Contact = () => {
  setWindowTitle("Contact")
  return (
    <LazyMotion features={domAnimation}>
      <Header current='Contact' />
      <motion.div
        animate={{
          opacity: 1,
        }}
        initial={{
          opacity: 0,
        }}
        exit={{
          opacity: 0,
        }}
        transition={{
          duration: 1,
        }}
      >
        <main>
          <div className='spacer'></div>
          <section className='contact_mainsection'>
            <h2>CONTACT</h2>
            <p>連絡はメール、またはInstagramでお受けしております。</p>

            <div className='spacer'></div>

            <a
              href='https://www.instagram.com/mm.chiyomi/'
              className='main-link contact-button'
            >
              Instagram
            </a>
            <div
              className='main-link contact-button'
              style={{ cursor: "pointer" }}
              onClick={linkToMail}
            >
              Mail
            </div>
          </section>
        </main>
      </motion.div>
      <Footer />
    </LazyMotion>
  )
}

const linkToMail = () => {
  const addrArr = ["nannoh", "since", "2003", "guhan"]
  const urlArr = ["mai", "@", "lto", "nifty"]
  window.location.href = `${urlArr[0]}${urlArr[2]}:${addrArr[3]}_${addrArr[1]}_${addrArr[2]}_${addrArr[0]}${urlArr[1]}${urlArr[3]}.com`
}
