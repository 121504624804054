import { useEffect, useMemo } from "react"
import { Pagination } from "swiper"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import { Swiper, SwiperSlide } from "swiper/react"
import { GalleryItemType } from "../../pages/Gallery"
import "./item-description.css"
export const Description = (props: {
  data?: GalleryItemType
  hook?: React.Dispatch<React.SetStateAction<GalleryItemType | undefined>>
}) => {
  const size = useMemo(() => {
    if (props.data?.size) {
      return JSON.parse(props.data.size)
    }
  }, [props.data])

  useEffect(() => {
    if (props.data) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "visible"
    }
    return () => {
      document.body.style.overflow = "visible"
    }
  }, [props.data])

  return (
    <div className={props.data ? "item-description show" : "item-description"}>
      {props.data ? (
        <>
          <span id='close' onClick={() => props.hook?.(undefined)}>
            ×
          </span>
          <h2>{props.data.name}</h2>

          <div>
            <Swiper pagination={true} modules={[Pagination]}>
              {JSON.parse(props.data.image).map((img: string) => {
                return (
                  <SwiperSlide key={img}>
                    <img
                      className='item-desc-img'
                      src={"https://m-chiyomi.com/apis/images/" + img}
                    />
                  </SwiperSlide>
                )
              })}
            </Swiper>
            <div>
              <p>
                {props.data.description}
                <br />
                Price:
                {props.data.price !== undefined && props.data.price > 0 ? (
                  <>
                    <b>\{props.data.price.toLocaleString()}</b>
                  </>
                ) : (
                  <>
                    <b>\ASK</b>
                  </>
                )}
                {props.data.stock > 0 ? "(販売中)" : "(売約済)"}
                <br />
                {size ? (
                  <>
                    幅: {size.y}mm, 高さ: {size.x}mm
                  </>
                ) : (
                  ""
                )}
              </p>
              <div className='sp-spacer'></div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  )
}
